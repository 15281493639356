svg { 
    position: relative; 
   z-index: -1; 
} 
ul { 
    list-style-type: none; 
    margin-top: 4px; 
    padding-left: 4px; 
}
li { 
    margin-bottom: 4px; 
}
.theme {
    float: right;
    margin-top: -42px;
}
.light svg {
    height: 24px; 
    width: 24px;
}
.light {    
    display: none;
    position: relative;
    z-index: 1;
}
.dark svg {
    height: 24px; 
    width: 24px;
}
.dark {
    z-index: 1;
    position: relative;
}
.next { 
    border-width: 2px;
}
#when { 
    height: 42px; 
}
.category .col {
    margin: 8px 0 8px 0; 
}
.capture-task { 
    height: 23ex; 
}
#amount { 
    width: 66%; 
    margin-top: 6px; 
}
#v { 
    display: none; 
}
.common select { 
    height: 42px; 
}
.btn-primary { 
    opacity: 75%; 
}
.done { 
    margin-top: 2px; 
}
.capture-items { 
    list-style-type: none; 
    margin-top: 4px;
    padding-left: 4px;
}
.capture-items .badge { 
    width: 17%;  
    max-width: 99px; 
}
.remove { 
    height: fit-content; 
    float: right; 
    z-index: 1; 
}
.bi-trash { 
    height: 20px; 
    width: 20px; 
}
.status { 
    position: fixed; 
    bottom: 0; 
    width:100%;
    display: flex;
    justify-content: center;
}
.show-alert {
    background-color: transparent;
    border-style: none;
    margin-bottom: 0px;
    padding: 16px 0px 0px 0px;
}
.bi-gear { 
    height: 18px; 
    width: 18px; 
    margin-left: 9px; 
}
.config { 
    display: inline-block; 
    width: 36px; 
    margin-left: -9px;
}
.bi-exclamation-triangle-fill {
    display: none;
    height: 20px; 
    width: 20px;
    margin: -18px -8px 0 -12px;
    z-index: 1;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
svg.bi-exclamation-triangle {
    animation: blink 1s;
    animation-iteration-count: infinite;
}